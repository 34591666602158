<template>
  <div>
    <TitleDashboard :breadcrumbsItems="items" :title="'Novedades'" />

    <v-row class="mt-4">
      <v-col cols="12" md="8">
        <v-card elevation="0" min-height="200px">
          <v-card-title> Nueva novedad </v-card-title>
          <v-card-subtitle>
            Para dar conocimiento de novedades, escriba en el cuadro de texto y
            luego precione enviar "PARA NOVEDADES DE EXTREMA IMPORTANCIA ULTICE HT"
          </v-card-subtitle>

          <v-card-text>
            <v-textarea
              outlined
              label="Nueva Novedad"
              :value="novedad"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="ma-2"
              :width="this.$vuetify.breakpoint.mobile ? '70%' : '30%'"
              dark
              color="success"
              @click.stop="dialog = true"
            >
              enviar
              <v-icon
        right
        dark
      >
        mdi-email-arrow-right
      </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <CardAsistencia />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import CardAsistencia from "@/components/Cards/CardAsistencia.vue";
import { mapState } from "vuex";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    CardAsistencia,
  },
  async created() {
    await this.$store.dispatch("elecciones/getEscuelas");
    console.log(">>>>", this.escuelas);
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Novedades",
          disabled: false,
          link: false,
        },
      ],
      novedad:"Escriba aqui"
    };
  },

  mounted() {
    this.datosUser = JSON.parse(localStorage.getItem("token"));
  },

  methods: {
    deleteItemList(id) {
      this.presentesList = this.presentesList.filter((elem) => elem.id != id);
    },
  },
  computed: mapState({
    escuelas: (state) => state.elecciones.escuelas,
  }),
};
</script>

<style></style>
