import Vue from "vue";

const state = () => ({
  escuelas: [],
  policeList: [],
});

const mutations = {
  setData(state, payload) {
    state[payload.key] = payload.value;
  },
  setPoliceList(state, payload) {
    state.policeList = [...state.policeList, payload];
  },
};

const actions = {
  async getEscuelas({ commit }) {
    return new Promise(async (resolve) => {
      try {
        let url = "/Escuelas/escuelas";
        const r = await Vue.prototype.$rest.get({ url });
        let mapResp = r.data.escuela.map((elem) => ({
          dataValue: elem.id_escuela,
          dataText: elem.escuela,
        }));
        commit("setData", {
          key: "escuelas",
          value: mapResp,
        });
        resolve();
      } catch (err) {
        console.log(err);
      }
    });
  },

  async getPolice({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = "/indice/policia";
        let params = payload;
        const r = await Vue.prototype.$rest.get({ url, params });

        console.log(r.data.user);
        commit("setPoliceList", {
          key: "policeList",
          value: r.data.user,
        });
        resolve();
      } catch (err) {
        console.log(err);
        reject();
      }
    });
  },
  async deletePolice({ commit, state }, payload) {
    try {
      console.log(state.userData);
      const updatedPoliceList = state.policeList.filter(
        (police) => police.value.id_policia !== payload
      );
      commit("setData", {
        key: "policeList",
        value: updatedPoliceList,
      });
    } catch (err) {
      console.log(err);
    }
  },
};

const getters = {
  escuelas(state) {
    return state.escuelas;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
