<template>
  <div>
    <v-row>
      <v-col
        md="7"
        class="d-flex justify-center align-center"
        v-if="this.$vuetify.breakpoint.smAndUp"
        style="background: #f4f5fa; height: 100vh"
      >
        <lottie :width="600" :options="defaultOptions" />
      </v-col>
      <v-col
        style="background: white"
        cols="12"
        md="5"
        class="d-flex justify-center align-center"
      >
        <v-card class="v-card--flat cardLogin" max-width="550">
          <v-card-text>
            <h5 class="text-h5 font-weight-semibold mb-1">
              Instituto Superior de Formación Técnica en Seguridad Pública
            </h5>
            <p class="mb-0">Por favor para continuar inicie sesion</p>
          </v-card-text>
          <div class="d-flex">
            <v-img class="logo" src="../assets/logo1.png" contain> </v-img>
          </div>
          <v-card-text>
            <v-form ref="form" action v-model="valid" lazy-validation>
              <v-card-text>
                <v-text-field
                  v-model="username"
                  :rules="nameRules"
                  label="Usuario"
                  prepend-inner-icon="mdi-account"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  :type="verContraseña ? 'text' : 'password'"
                  :rules="ReglasContraseña"
                  label="Contraseña"
                  prepend-inner-icon="mdi-key"
                  :append-icon="verContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="verContraseña = !verContraseña"
                  required
                ></v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  block
                  color="primary"
                  @click="submit"
                >
                  <v-icon left> mdi-email-arrow-right </v-icon>
                  ingresar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
          <v-card class="mx-auto" max-width="344" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">IMPORTANTE</div>

                <v-list-item-subtitle class="text-truncate"
                  >Si necesitas ayuda, contáctanos<br />
                  por WhatsApp</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-avatar size="80" color="grey">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img
              ></v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
              <v-btn rounded block small color="success" @click="msjWhatsapp()">
                enviar mensaje
                <v-icon right dark> mdi-whatsapp </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as animationData from "../assets/lottiesfile/login.json";

export default {
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
      },
      loading: false,
      valid: false,
      verContraseña: false,
      password: "",
      username: "",
      nameRules: [
        (v) => !!v || "Ingresar Usuario",
        //(v) => v.length <= 10 || "El usuario debe contener minimo 10",
        // (v) =>  /(\d)/.test(v) || "debe tener un numero"
      ],
      ReglasContraseña: [
        (v) => !!v || "Ingresar contraseña",
        // (v) => v.length <= 3 || "La contraseña debe contener minimo 4 caracteres",
        // (v) => /([A-Z])/.test(v)  || "Debe contener una mayuscula",
      ],
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await this.$store.dispatch("user/login", {
            user: this.username,
            password: this.password,
          });
        } catch (e) {
          console.log(">>>>cat", e);
        } finally {
          this.loading = false;
        }
      }
    },
    msjWhatsapp() {
      const phoneNumber = "542645202530";
      const message = "Buenos días, tengo la siguiente novedad";
      const encodedMessage = encodeURIComponent(message);
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

      window.location.href = whatsappURL;
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .logo {
    width: 320px;
    height: 85px !important;
  }
  .cardLogin {
    margin-top: 30px;
  }
}
</style>
