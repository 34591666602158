<template>
  <div>
    <v-card class="rounded-lg pr-4 pl-4" elevation="3">
      <v-card-title>
        <v-icon large left> mdi-monitor-cellphone </v-icon>
        <span class="text-h6 font-weight-boldt">Comando Electoral</span>
      </v-card-title>

      <v-card-text class="text-h5 font-weight-bold">
        ¡No olvides que estamos en línea para ayudarte siempre que lo necesites!
      </v-card-text>
      
      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-avatar size="50" color="grey">
            <v-img
              class="elevation-6"
              alt=""
              src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title style="font-size: 14px;">Cabo 1° Esquivel Leonardo</v-list-item-title>
            <small>Area Sistemas</small>
          </v-list-item-content>
          <v-list-item-content>
            <div class="text-center">
              <v-btn fab dark class="ma-0" color="success" @click="msjWhatsapp()">
                <v-icon size="40" dark> mdi-whatsapp </v-icon>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CardAsistencia",
  methods: {
    msjWhatsapp() {
      const phoneNumber = "542645202530";
      const message = "Buenos días, tengo la siguiente novedad";
      const encodedMessage = encodeURIComponent(message);
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

      window.location.href = whatsappURL;
    },
  },
};
</script>

<style lang="scss" scoped></style>
