<template>
  <div>
    <TitleDashboard
      :breadcrumbsItems="items"
      :title="'Estado Del Establecimiento'"
    />

    <v-row class="mt-4">
      <v-col cols="12" md="8">
        <v-card elevation="0" min-height="200px">
          <v-card-title> Estado General </v-card-title>
          <v-card-subtitle>
            Para habilitar, agregar urnas y actualizar el porsentaje de votantes
            utilice los siguientes campos y luego pulse el boton "enviar"
          </v-card-subtitle>

          <v-card-text>
            <v-switch
              v-model="StateSchool"
              :label="
                StateSchool ? 'Escuela Habilitada' : 'Escuela No Habilitada'
              "
            ></v-switch>

            <v-select
              :items="itemUrnas"
              label="Seleccione cantidad de urnas"
              outlined
            ></v-select>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-text>
            <p>Porcentaje de Votantes</p>
            <v-text-field
              label="Escriba el porcentaje"
              outlined
              type="Number"
              :value="porcentaje"
              suffix="%"
            ></v-text-field>
            <v-progress-linear v-model="porcentaje" height="25">
              <strong>{{ Math.ceil(porcentaje) }}%</strong>
            </v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-btn>enviar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <CardAsistencia />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleDashboard from "@/components/dashboard/TitleDashboard.vue";
import CardAsistencia from "@/components/Cards/CardAsistencia.vue";
import { mapState } from "vuex";

export default {
  name: "Inicio",
  components: {
    TitleDashboard,
    CardAsistencia,
  },
  async created() {
    await this.$store.dispatch("elecciones/getEscuelas");
    console.log(">>>>", this.escuelas);
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Estado de Escuela ",
          disabled: false,
          link: false,
        },
      ],
      porcentaje: 0,
      itemUrnas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    };
  },

  mounted() {
    this.datosUser = JSON.parse(localStorage.getItem("token"));
  },

  methods: {
    deleteItemList(id) {
      this.presentesList = this.presentesList.filter((elem) => elem.id != id);
    },
  },
  computed: mapState({
    escuelas: (state) => state.elecciones.escuelas,
  }),
};
</script>

<style></style>
