<template>
  <div>
    <v-app-bar app color="#f6f6f6" elevate-on-scroll elevation="4">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <!-- <v-toolbar-title>Title</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            style="cursor: pointer"
            class="mx-5 mr-10"
          >
            <v-chip link>
              <v-badge dot bottom offset-y="10" offset-x="10" color="green">
                <v-avatar size="40">
                  <v-img
                    src="@/assets/user.jpeg"
                  />
                </v-avatar>
              </v-badge>
              <!-- <span>{{ datosUser.nombre }}</span> -->
              <span>{{ userData.nombre }}</span>
            </v-chip>
          </span>
        </template>
        <v-list width="250" class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-img
                width="50"
                src="@/assets/user.jpeg"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
             {{ userData.nombre }}
                
              </v-list-item-title>
              <v-list-item-subtitle> En Linea </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="{ icon, title } in menus"
            :key="icon"
            link
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!--! SIDEBAR -->

    <v-navigation-drawer v-model="drawer" app>
      <v-img class="pa-4 img-sidebar" src="@/assets/escudo-police.jpeg">
        <div class="text-center mt-4"></div>
      </v-img>

      <v-divider></v-divider>
      <v-list>
        <v-list-item-group v-model="group">
          <div v-for="[icon, text, link] in links" :key="icon">
            <v-list-item link @click="$router.push(link).catch((err) => {})">
              <v-list-item-icon>
                <v-icon>{{ icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      links: [
        ["mdi-inbox-arrow-down", "Inicio", "/", 1],
        ["mdi-account-arrow-right", "Entradas", "/entradas", 1],
        ["mdi-account-arrow-left", "Salidas", "/salidas", 1],
        ["mdi-text-box-edit-outline", "Novedades", "/novedades", 1],
        ["mdi-clipboard-list-outline", "Estado de Escuela", "/escuela", 1],
      ],
      group: null,
      drawer: null,
      
      menus: [
        { title: "Salir", icon: "mdi-logout" },
      ],
    };
  },
  methods:{
    logout(){
      
      this.$store.dispatch('user/logout')
    }
  },
  computed: mapState({
    userData: (state) => state.user.userData,
    
  }),
};
</script>

<style lang="scss" scoped>
.img-sidebar{
  background: #56CCF2;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}</style>
